import { valueOrEnvVariable } from '@blocksure/blocksure-core/dist/src/utilities';

type FeatureFlag = {
    menuReports?: boolean;
    menuReportsOld?: boolean;
    menuTools?: boolean;
    surebyld?: boolean;
}

const FeatureFlags: Record<string, FeatureFlag> = {
    dev: {
        menuReports: true,
        menuTools: true,
        surebyld: true,
    },
    demo: {
        menuReportsOld: true,
        menuTools: true,
        surebyld: true,
    },
    uatjp: {
        menuReportsOld: true,
    },
    uatjapan: {
        menuReportsOld: true,
    }
};

["devr", "sysm", "sysr", "antonio", "alex", "chris", "denis", "dimash", "mpetuska", "oleh"].forEach(it => FeatureFlags[it] = FeatureFlags["dev"]);

const envJSON = window["env"] || { env: "dev" };
const currentEnv = valueOrEnvVariable(envJSON.env, "dev");  // localhost has "!!env!!" value

export function isFeatureEnabled(flagName: keyof FeatureFlag): boolean {
    return FeatureFlags[currentEnv]?.[flagName] || false;
}

export function isEnvProdLike() {
    return currentEnv.startsWith("prod");
}

export function isEnvUATLike() {
    return currentEnv.startsWith("uat");
}
